import React, { useMemo, useState } from "react";
import { FilterOutlined, ReloadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Flex,
  Form,
  Input,
  Row,
  theme,
  SelectProps,
  Select,
  InputNumber,
  ConfigProvider,
  FormInstance,
} from "antd";

import {
  useCustomFields,
  useInteractiveFilter,
  useMediaQuery,
} from "hooks/app";

import { useTranslation } from "react-i18next";
import { DocumentTypes } from "types/recurring_transactions";
import { CDStatuses, CustomFiledType } from "types/app";
import SelectLocations from "components/tenant/form/SelectLocations";
import CustomField from "components/tenant/form/CustomField";
import { useRTPermissions } from "hooks/recurring_transactions";
import DueIssueDatePicker from "components/tenant/form/DueIssueDatePicker";

type Props = Pick<
  ReturnType<typeof useInteractiveFilter>,
  "form" | "update" | "setFormContainerRef"
> & { documentType: DocumentTypes | null; resetSelection: () => void };

const translationTerms = {
  invoiceNo: "activerecord.external_documents.invoice.reference",
  reference: "activerecord.external_documents.bill.reference",
  posInvoiceNo: "activerecord.attributes.invoice.pos_number",
  customerName: "forms.placeholders.customer_name",
  vendorName: "forms.placeholders.vendor_name",
  statuses: {
    status: "forms.placeholders.status",
    Approved: "status.approved",
    Paid: "status.paid",
    "Partially Paid": "status.partially_paid",
  },
  "Issue Date": "dropdowns.issue_date",
  "Due Date": "dropdowns.due_date",
  min: "forms.placeholders.min",
  max: "forms.placeholders.max",
  from: "forms.placeholders.from",
  to: "forms.placeholders.to",
  filter: "forms.buttons.filter",
  reset: "forms.buttons.reset",
  custom_fields: "activerecord.attributes.cpn_custom_field.custom_fields",
};

const statusKeys: Extract<
  CDStatuses,
  "Approved" | "Paid" | "Partially Paid"
>[] = ["Approved", "Paid", "Partially Paid"];

const dateKeys = ["Issue Date", "Due Date"] as const;

// Main Component
const DocumentFitlers = ({
  form,
  setFormContainerRef,
  update,
  documentType,
  resetSelection,
}: Props) => {
  const { t } = useTranslation();

  const { token } = theme.useToken();

  const { data, isLoading } = useCustomFields(documentType);

  const is1920Screen = useMediaQuery("3xl:(size>=1600)");

  const isMobile = useMediaQuery("sm:(size<=576)");

  const { isPermissionsLoading } = useRTPermissions();

  const [customFieldType, setCustomFieldType] =
    useState<CustomFiledType | null>(null);

  const resetFilterHandler = () => {
    if (form.isFieldsTouched()) {
      form.resetFields();
      setCustomFieldType(null);
      update();
      resetSelection();
    }
  };

  const filterSatusOptions: SelectProps["options"] = useMemo(
    () =>
      statusKeys.map((key) => ({
        label: t(translationTerms["statuses"][key]),
        value: key,
      })),
    []
  );

  const dateOptions: SelectProps["options"] = useMemo(
    () =>
      dateKeys.map((key) => ({
        label: t(translationTerms[key]),
        value: key,
      })),
    []
  );

  const customFieldsOptions: SelectProps["options"] = useMemo(
    () =>
      data?.custom_fields.map((cpn) => ({
        label: cpn.field_name,
        value: cpn.id,
        type: cpn.field_type,
      })),
    [data]
  );

  const customFieldChangeHandler: SelectProps["onChange"] = (_, options) => {
    if (!Array.isArray(options)) {
      form.setFieldValue("q[custom_field_values_value_cont]", undefined);
      setCustomFieldType(options.type);
    }
  };

  return (
    <ConfigProvider theme={{ components: { Form: { itemMarginBottom: 0 } } }}>
      <div
        style={{ maxWidth: is1920Screen ? token.screenXL : "auto" }}
        ref={setFormContainerRef}
      >
        <Form
          size={isMobile ? "small" : "middle"}
          form={form}
          initialValues={{
            date: "Issue Date",
          }}
          disabled={isPermissionsLoading}
        >
          <Row wrap gutter={[token.margin, token.marginMD]}>
            <Col span={12} lg={{ span: 8 }} xl={{ span: 4 }}>
              <Form.Item name={"q[reference_cont]"}>
                <Input
                  placeholder={
                    documentType === "Invoices"
                      ? t(translationTerms["invoiceNo"])
                      : t(translationTerms["reference"])
                  }
                />
              </Form.Item>
            </Col>

            {documentType === "Invoices" && (
              <Col span={12} lg={{ span: 8 }} xl={{ span: 4 }}>
                <Form.Item name={"q[client_generated_id_cont]"}>
                  <Input placeholder={t(translationTerms["posInvoiceNo"])} />
                </Form.Item>
              </Col>
            )}

            <Col span={12} lg={{ span: 8 }} xl={{ span: 4 }}>
              <Form.Item name={"q[contact_name_cont]"}>
                <Input
                  placeholder={
                    documentType === "Invoices"
                      ? t(translationTerms["customerName"])
                      : t(translationTerms["vendorName"])
                  }
                />
              </Form.Item>
            </Col>

            <Col span={12} lg={{ span: 8 }} xl={{ span: 4 }}>
              <Form.Item name={"q[status_eq]"}>
                <Select
                  style={{ width: "100%" }}
                  allowClear
                  placeholder={t(translationTerms["statuses"]["status"])}
                  options={filterSatusOptions}
                />
              </Form.Item>
            </Col>

            <Col span={12} lg={{ span: 8 }} xl={{ span: 4 }}>
              <Form.Item name={"q[total_amount_gteq]"}>
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  placeholder={t(translationTerms["min"])}
                />
              </Form.Item>
            </Col>

            <Col span={12} lg={{ span: 8 }} xl={{ span: 4 }}>
              <Form.Item name={"q[total_amount_lteq]"}>
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  placeholder={t(translationTerms["max"])}
                />
              </Form.Item>
            </Col>

            <Col span={12} lg={{ span: 8 }} xl={{ span: 4 }}>
              <Form.Item name={"date"}>
                <Select style={{ width: "100%" }} options={dateOptions} />
              </Form.Item>
            </Col>

            <DueIssueDatePicker
              columnsConfig={{ span: 12, lg: { span: 8 }, xl: { span: 4 } }}
            />

            <Col span={12} lg={{ span: 8 }} xl={{ span: 4 }}>
              <Form.Item
                name={"inventory_list[]"}
                normalize={(v) => (v?.length > 0 ? v : null)}
              >
                <SelectLocations />
              </Form.Item>
            </Col>

            <Col span={12} lg={{ span: 8 }} xl={{ span: 4 }}>
              <Form.Item name={"q[custom_field_values_cpn_custom_field_id_eq]"}>
                <Select
                  onChange={customFieldChangeHandler}
                  placeholder={t(translationTerms["custom_fields"])}
                  loading={isLoading}
                  disabled={isLoading}
                  options={customFieldsOptions}
                />
              </Form.Item>
            </Col>

            <Col span={12} lg={{ span: 8 }} xl={{ span: 4 }}>
              <CustomField type={customFieldType} />
            </Col>

            <Col>
              <FilterActions
                resetFilterHandler={resetFilterHandler}
                update={update}
                resetSelection={resetSelection}
                form={form}
              />
            </Col>
          </Row>
        </Form>
      </div>
    </ConfigProvider>
  );
};

const FilterActions = ({
  update,
  resetFilterHandler,
  resetSelection,
  form,
}: Pick<Props, "update"> & {
  resetFilterHandler: () => void;
  resetSelection: () => void;
  form: FormInstance;
}) => {
  const { token } = theme.useToken();

  const { t } = useTranslation();

  const filterHandler = () => {
    if (form.isFieldsTouched()) {
      update();
      resetSelection();
    }
  };

  return (
    <Flex gap={token.marginSM}>
      <Button type="primary" icon={<FilterOutlined />} onClick={filterHandler}>
        {t(translationTerms.filter)}
      </Button>
      <Button icon={<ReloadOutlined />} onClick={resetFilterHandler}>
        {t(translationTerms.reset)}
      </Button>
    </Flex>
  );
};

export default DocumentFitlers;
