import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Button, Input, Tooltip, Modal, Form, Select } from "antd";
import React, { useState, useContext, useEffect, useMemo } from "react";
import {
  EyeOutlined,
  EditOutlined,
  SyncOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { BudgetContext } from "components/tenant/budgets/BudgetDetails";
const { Option } = Select;
import { ColumnsType } from "antd/es/table";
import { useMutation } from "react-query";
import { postBudgetDetails } from "api/budgets";
import { BudgetContextType, BudgetDetailsType } from "types/budget";

export const useTableColumns = () => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("budgets.attributes.reference"),
      dataIndex: "code",
      key: "id",
      sorter: true,
      SortOrder: "descend",
    },
    {
      title: t("budgets.attributes.name"),
      dataIndex: i18next.language === "en" ? "name_en" : "name_ar",
      key: i18next.language === "en" ? "name_en" : "name_ar",
      sorter: true,
    },
    {
      title: t("budgets.attributes.budget_type"),
      dataIndex: "kind",
      key: "kind",
      sorter: true,
    },
    {
      title: t("budgets.attributes.fiscal_year"),
      dataIndex: "fiscal_year",
      key: "fiscal_year",
      sorter: true,
    },
    {
      title: t("budgets.attributes.actions"),
      key: "actions",
      render: (text, record) => (
        <>
          <Button
            icon={<EyeOutlined />}
            href={`/tenant/budgets/${record.id}`}
            style={{ marginRight: 8 }}
          />
          <Button
            icon={<EditOutlined />}
            href={`/tenant/budgets/${record.id}/edit`}
            style={{ marginRight: 8 }}
          />
        </>
      ),
    },
  ];

  return {
    columns,
  };
};

export const useBudgetDetailsColumns = (
  cycle,
  data,
  dateRanges,
  formData,
  id,
  isSaveButtonDisabled,
  handleBlur,
  handleInputChange,
  showResetRowModal,
): ColumnsType<any> => {
  const updateBudgetDetails = useMutation({
    mutationFn: (data: BudgetDetailsType) => postBudgetDetails(data, id),
  });
  const { t } = useTranslation();
  const handleSave = () => {
    const convertObjectToArray = (obj) => {
      return Object.entries(obj).map(([key, value]) => {
        const valuesArray = Array.from({ length: cycle.no }, (_, index) => {
          const valueNumber = parseFloat(value[index + 1]) || 0;
          return valueNumber;
        });
        return {
          budgeting_id: key,
          values: valuesArray,
        };
      });
    };

    const formDataResult = new FormData();

    const result = convertObjectToArray(formData);

    result.forEach((el, i) => {
      formDataResult.append(`budget[budgeting_values][]`, JSON.stringify(el));
    });

    formDataResult.append("budget[cycle]", cycle.type);

    updateBudgetDetails.mutate(formDataResult as any, {});
  };
  const renderEditableInput = (record, field) => {
    const isDisabled = record.children && record.children.length > 0;
    let formDataField = formData[record.id]?.[field]
    const { modalOpen, setModalOpen, selectedRow, setSelectRow } =
      useContext<BudgetContextType>(BudgetContext);
    return (
      <Input
        type="text"
        style={{ width: cycle.no === 12 ? "130px" : "150px" }}
        value={isDisabled ? Intl.NumberFormat().format(formDataField): formDataField || 0}
        onChange={(e) => handleInputChange(e, record.id, field)}
        onBlur={(e) => handleBlur(e, record.id, field)}
        disabled={isDisabled}
        suffix={
          <span
            style={{
              visibility:
              !isDisabled && 
              Number(formData[record.id]?.[field]) > 0&&
                data?.dimensions.length>0
                  ? "visible"
                  : "hidden",
            }}
          >
            <Tooltip
              color="#f2f2f2"
              style={{ cursor: "pointer" }}
              title={
                <button
                  onClick={() => {
                    setModalOpen(true);
                    setSelectRow({ ...record, field: field });
                    if (!isSaveButtonDisabled) {
                      handleSave();
                    }
                  }}
                  style={{
                    outline: "none",
                    border: "none",
                    textAlign: "center",
                    width: "80px",
                    display: "inline-block",
                    color: "black",
                  }}
                >
                  {t("budgets.break_down_popup.break")}
                </button>
              }
            >
              <EllipsisOutlined
                style={{ transform: "rotate(90deg)", cursor: "pointer" }}
                className="ellipsis-icon"
              />
            </Tooltip>
          </span>
        }
      />
    );
  };
  const columns: ColumnsType<any> = [
    {
      title: t("budgets.show.budget_details.codes"),
      dataIndex: "code",
      key: "id",
      width: 180,
      fixed: "left",
    },
    {
      title: t("budgets.show.budget_details.name"),
      dataIndex: "name",
      key: "name",
      width: 150,
      fixed: "left",
    },
    {
      title:
        data?.base === "quantity"
          ? `${t("budgets.show.budget_details.budget_quantity")}`
          : `${t("budgets.show.budget_details.budget_amount")} `,
      dataIndex: "budget_amount",
      key: "budget_amount",
      fixed: "left",
      render: (_, record) => (
        <Input
          style={{ width: cycle.no === 12 ? "130px" : "150px" }}
          value={ Intl.NumberFormat().format(formData[record.id]?.budget_amount || 0)}
          disabled
        />
      ),
    },
    ...(Array.isArray(dateRanges)
      ? dateRanges.map((year, i) => {
          const date = moment(year, "D-MM-YYYY");
          const fiscalYear = date.year();
          const shortName = date.format("MMM");
          let title;
          if (dateRanges.length === 4) {
            const startShortName = moment(year[0], "D-MM-YYYY").format("MMM");
            const endShortName = moment(year[1], "D-MM-YYYY").format("MMM");
            title = `${startShortName}-${endShortName} ${fiscalYear}`;
          } else {
            title = `${shortName} ${fiscalYear}`;
          }
          return {
            title: title,
            dataIndex: i + 1,
            key: `${i + 1}_${fiscalYear}`,
            width: cycle.no === 12 ? 100 : 200,
            render: (_, record) => renderEditableInput(record, i + 1),
          };
        })
      : []),
    {
      title: "",
      key: "resetIcon",
      fixed: "right",
      render: (_, record) => {
        return record.children && record.children.length > 0 ? null : (
          <SyncOutlined
            style={{ fontSize: "20px" }}
            onClick={() => showResetRowModal(record.id)}
          />
        );
      },
    },
  ];
  return columns;
};

export const useResetAllModal = (handleReset) => {
  const { t } = useTranslation();
  const [isResetModalVisible, setIsResetModalVisible] = useState(false);

  const showResetModal = () => {
    setIsResetModalVisible(true);
  };

  const handleCancel = () => {
    setIsResetModalVisible(false);
  };

  const handleOk = () => {
    handleReset();
    setIsResetModalVisible(false);
  };

  const resetModal = (
    <Modal
      title={t("budgets.reset_details_popup.title")}
      visible={isResetModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={t("budgets.reset_details_popup.confirm")}
      cancelText={t("budgets.reset_details_popup.cancel")}
    >
      <p>{t("budgets.reset_details_popup.description")}</p>
    </Modal>
  );

  return {
    showResetModal,
    resetModal,
  };
};

export const useResetRowDataModal = (handleResetRow) => {
  const { t } = useTranslation();
  const [isResetRowModalVisible, setIsResetRowModalVisible] = useState(false);
  const [currentRecordId, setCurrentRecordId] = useState(null);

  const showResetRowModal = (recordId) => {
    setCurrentRecordId(recordId);
    setIsResetRowModalVisible(true);
  };

  const handleCancel = () => {
    setIsResetRowModalVisible(false);
  };

  const handleOk = () => {
    if (currentRecordId !== null) {
      handleResetRow(currentRecordId);
    }
    setIsResetRowModalVisible(false);
  };

  const resetRowModal = (
    <Modal
      title={t("budgets.reset_row_popup.title")}
      visible={isResetRowModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={t("budgets.reset_row_popup.confirm")}
      cancelText={t("budgets.reset_row_popup.cancel")}
    >
      <p>{t("budgets.reset_row_popup.description")}</p>
    </Modal>
  );

  return {
    showResetRowModal,
    resetRowModal,
  };
};

export const useDynamicDimensionsColumns = (
  data,
  handleSelectChange,
  tableData
) => {
  const [dynamicDimensionsColumns, setDynamicDimensionsColumns] = useState([]);
  useEffect(() => {
    if (data) {
      setDynamicDimensionsColumns(
        data?.dimensions.map((el, i) => ({
          title: `${el.code}-${
            i18next.language === "en" ? el.name_en : el.name_ar
          }`,
          dataIndex: el.code,
          key: `${el.code}-${i}`,
          width: 200,
          render: (text, record) => {
            let defaultValue;

            record?.dimensions_values?.forEach((dim) => {
              if (dim.dimension_id == el.id) {
                defaultValue = dim.id;
              }
            });
            return (
              <Form.Item
                name={[record.key, "dimensions_values", i]}
                initialValue={defaultValue}
                rules={[{ required: false, message: `${el.code} is required` }]}
                key={`form-item-${record.key}-${i}`}
                style={{ marginBottom: 0 }}
              >
                <Select
                  value={defaultValue}
                  onChange={(value) =>
                    handleSelectChange(value, el.id, record.key)
                  }
                  style={{ width: "100%" }}
                >
                  {el?.values.map((value) => {
                    if (value.value_type == "standard") {
                      return (
                        <Option key={value.id} value={value.id}>
                          {`${value.code}-${
                            i18next.language === "en"
                              ? value.name_en
                              : value.name_ar
                          }`}
                        </Option>
                      );
                    }

                    return null;
                  })}
                </Select>
              </Form.Item>
            );
          },
        }))
      );
    }
  }, [data]);

  return dynamicDimensionsColumns;
};

export const useBreakDownModalState = (selectedRow) => {
  const [tableData, setTableData] = useState([]);
  const [breakDownInputsValues, setBreakDownInputsValues] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setTableData([
      {
        key: 1,
        kind: `${selectedRow?.name}-${selectedRow?.code}`,
        amount: "0",
      },
    ]);
  }, [selectedRow]);

  return {
    tableData,
    setTableData,
    breakDownInputsValues,
    setBreakDownInputsValues,
    errorMessage,
    setErrorMessage,
  };
};
