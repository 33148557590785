import React, { useState } from "react";
import { Button, Input, message, Row, Col } from "antd";
import { CopyOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { ReferralCardProps } from "types/referrals";
import { useTranslation } from "react-i18next";
import { useReferralData } from "hooks/referrals";

const ReferralLinkInput: React.FC<ReferralCardProps> = ({ buttonText }) => {
  const { t } = useTranslation();
  const { referralUrl, loading } = useReferralData();
  const [copied, setCopied] = useState<boolean>(false);
  const linkCopied=t("referrals.index.referral_card.copied_link")

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(referralUrl);
      message.success(t("referrals.index.referral_card.success_message"));
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    } catch (error) {
      message.error(t("referrals.index.referral_card.fail_message"));
    }
  };

  return (
    <Row gutter={[10, 10]} justify="start" style={{ width: "90%" }}>
      <Col style={{ flex: 1 }}>
        <Input value={referralUrl} readOnly style={{ width: "100%" }} disabled/>
      </Col>
      <Col>
        <Button
          type={copied ? "default" : "primary"}
          onClick={handleCopy}
          icon={copied ? <CheckCircleOutlined /> : <CopyOutlined />}
          disabled={copied}
        >
          {copied ? linkCopied : buttonText}
        </Button>
      </Col>
    </Row>
  );
};

export default ReferralLinkInput;
