import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Layout, Table, Tabs, theme } from "antd";
import QContent from "components/layout/QContent";
import SkipProcessModal from "components/tenant/recurring_transactions/SkipProcessModal";
import {
  useExecuteSchedule,
  useRecurringTransactionSchedules,
  useTransactionsColumns,
} from "hooks/recurring_transactions";
import styles from "styles/layout.scss";
import { RTDocumentsTypes, RTTabs } from "types/recurring_transactions";

const { useToken } = theme;

const RecurringTransactionsInDashboard = () => {
  const { token } = useToken();
  const { t } = useTranslation();

  const PAGE_SIZE = 10;

  const tabs = [
    { key: "All", label: t("templates.projects.all") },
    { key: "Invoice", label: t("activerecord.attributes.dashboard.invoices") },
    { key: "Bill", label: t("activerecord.attributes.dashboard.bills") },
    { key: "SimpleBill", label: t("activerecord.attributes.layout.simple_bills") },
  ];

  const [isSkipProcessModalOpen, setIsSkipProcessModalOpen] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<RTDocumentsTypes | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);
  const selectedTransactionId = useRef<number | null>(null);

  const { mutate: runProcessMutate, isLoading: isRunProcessing } = useExecuteSchedule();
  const { mutateAsync: skipProcessMutateAsync, isLoading: isSkipProcessing } = useExecuteSchedule();

  const { data, isSchedulesLoading } = useRecurringTransactionSchedules(
    currentPage,
    PAGE_SIZE,
    selectedTab
  );

  const RTSchedulesData = data?.recurring_transaction_schedules || [];

  const onTabsChange = (key: RTTabs) => {
    setCurrentPage(1);
    if (key === "All") {
      setSelectedTab(null);
    } else {
      setSelectedTab(key);
    }
  };

  const handleStartProcess = (id: number) => {
    runProcessMutate({ id, operation: "run" });
  };

  const handleSkipProcess = async () => {
    try {
      if (selectedTransactionId.current) {
        await skipProcessMutateAsync({ id: selectedTransactionId.current, operation: "skip" });
      }
    } catch (error) {
      // console.log(error);
    } finally {
      setIsSkipProcessModalOpen(false);
    }
  };

  const openSkipProcessModal = (id: number) => {
    setIsSkipProcessModalOpen(true);
    selectedTransactionId.current = id;
  };

  const { columns } = useTransactionsColumns({
    handleStartProcess,
    openSkipProcessModal,
    isRunProcessing,
  });

  return (
    <Layout style={{ marginBottom: token.marginXXL }} className={styles.q_ant_input__radio}>
      <QContent title={t("recurring_transactions.details")}>
        <Tabs
          onChange={onTabsChange}
          type="card"
          tabBarStyle={{ marginBottom: 0 }}
          items={tabs.map((tab) => {
            return {
              label: tab.label,
              key: tab.key,
              children: (
                <Table
                  columns={columns}
                  dataSource={RTSchedulesData}
                  expandable={{
                    expandedRowKeys,
                    onExpand: (expanded, record) =>
                      expanded ? setExpandedRowKeys([record.reference]) : setExpandedRowKeys([]),
                    childrenColumnName: "original_documents",
                  }}
                  rowKey="reference"
                  virtual
                  scroll={{ x: 1000, y: 1000 }}
                  loading={isSchedulesLoading}
                  pagination={{
                    current: currentPage,
                    total: data?.meta?.total_pages || 1 * PAGE_SIZE,
                    hideOnSinglePage: true,
                    onChange: (page) => setCurrentPage(page),
                  }}
                />
              ),
            };
          })}
        />
        <SkipProcessModal
          isModalOpen={isSkipProcessModalOpen}
          setIsModalOpen={setIsSkipProcessModalOpen}
          onSkipProcess={handleSkipProcess}
          isSkipping={isSkipProcessing}
        />
      </QContent>
    </Layout>
  );
};

export default RecurringTransactionsInDashboard;
