import React from "react";
import { Flex } from "antd";
import { TrophyOutlined, GiftFilled } from "@ant-design/icons";
import CardContent from "./CardContent";
import { CardData } from "types/referrals";

const RewardCard: React.FC<CardData> = ({
  title,
  description,
  rewardsCount,
  color = "#1890ff",
}) => {
  return (
    <Flex align="center" gap="1.2em" style={{ width: "100%", padding: "1em" }} vertical>
      {rewardsCount > 0 ? (
        <TrophyOutlined style={{ fontSize: "72px", color: color }} />
      ) : (
        <GiftFilled style={{ fontSize: "72px", color: "#1890ff" }} />
      )}
      <CardContent title={title} description={description} alignment="center" />
    </Flex>
  );
};

export default RewardCard;