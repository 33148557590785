import React from "react";
import { Col, ColProps, ConfigProvider, DatePicker, Form } from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

const translationTerms = {
  from: "forms.placeholders.from",
  to: "forms.placeholders.to",
};

const DueIssueDatePicker = ({
  columnsConfig,
  dateTypeInputName = "date",
}: {
  columnsConfig: ColProps;
  dateTypeInputName?: string;
}) => {
  const { t, i18n } = useTranslation();

  return (
    <ConfigProvider
      theme={{
        components: {
          DatePicker: { cellWidth: i18n.language === "ar" ? 49 : 36 },
        },
      }}
    >
      <Col {...columnsConfig}>
        <Form.Item
          shouldUpdate={(prevValues, nextValues) =>
            prevValues.date !== nextValues.date
          }
        >
          {({ getFieldValue }) => (
            <Form.Item
              getValueProps={(value) => ({
                value: value && dayjs(value),
              })}
              normalize={(value) =>
                value && `${dayjs(value).format("YYYY-MM-DD")}`
              }
              name={
                getFieldValue(dateTypeInputName) === "Due Date"
                  ? "q[due_date_gteq]"
                  : "q[issue_date_gteq]"
              }
            >
              <DatePicker
                style={{ width: "100%" }}
                placeholder={t(translationTerms["from"])}
              />
            </Form.Item>
          )}
        </Form.Item>
      </Col>

      <Col {...columnsConfig}>
        <Form.Item
          shouldUpdate={(prevValues, nextValues) =>
            prevValues.date !== nextValues.date
          }
        >
          {({ getFieldValue }) => {
            return (
              <Form.Item
                getValueProps={(value) => ({
                  value: value && dayjs(value),
                })}
                normalize={(value) =>
                  value && `${dayjs(value).format("YYYY-MM-DD")}`
                }
                name={
                  getFieldValue(dateTypeInputName) === "Due Date"
                    ? "q[due_date_lteq]"
                    : "q[issue_date_lteq]"
                }
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder={t(translationTerms["to"])}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>
    </ConfigProvider>
  );
};

export default DueIssueDatePicker;
