import React from "react";
import { Flex } from "antd";
import ReferralLinkInput from "./ReferralLinkInput";
import CardContent from "./CardContent";
import { ReferralCardProps } from "types/referrals";

const ReferralCard: React.FC<ReferralCardProps> = ({
  title,
  description,
  buttonText,
}) => {
  return (
    <Flex align="start" gap="1.2em" style={{ width: "100%", padding: "1em" }} vertical>
      <CardContent title={title} description={description} alignment="start" />
      <ReferralLinkInput
        buttonText={buttonText}
        description=""
        showInput={true}
        title=""
        rewardsCount={0}
      />
    </Flex>
  );
};

export default ReferralCard;
