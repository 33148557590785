import { fetchReferralLink } from "api/referrals";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { ReferralData } from "types/referrals";

export const useReferralData = () => {
  const { data, isLoading } = useQuery<ReferralData>("referralData", fetchReferralLink);

  return {
    rewardsCount: data?.referrals_count || 0,
    referralUrl: data?.referral_url || "",
    loading: isLoading,
  };
};

export const useBreadCrumbData = () => {
  
  const { t } = useTranslation();
  return [{ title: t("referrals.name") }];
};
