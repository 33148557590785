import React, { useMemo, useRef, useState } from "react";
import {
  Badge,
  Button,
  Flex,
  Space,
  Table,
  TableProps,
  theme,
  Tooltip,
  Typography,
} from "antd";
import {
  RTRecord,
  ScheduleData,
  ScheduleType,
  TransactionDocumentType,
} from "types/recurring_transactions";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import Status from "./Status";
import {
  CaretRightOutlined,
  CloseOutlined,
  EyeOutlined,
  QuestionCircleOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import {
  useExecuteSchedule,
  useRTPermissions,
} from "hooks/recurring_transactions";
import SkipProcessModal from "../SkipProcessModal";

interface Props {
  schedulesData: ScheduleData[];
  isLoading: boolean;
  type: ScheduleType;
  totalEntries: number | undefined;
  pageSize: number | undefined;
  onChange: TableProps["onChange"];
  anyFailed?: boolean;
  currentPage?: number;
  documentType?: TransactionDocumentType;
  RTStatus: RTRecord["status"] | undefined;
  isRefetchingSchedules?: boolean;
  refetchSchedules?: () => Promise<void>;
}

const translationTerms = {
  title: {
    [ScheduleType.UPCOMMING]:
      "recurring_transactions.show.schedules.scheduled_title",
    [ScheduleType.PASSED]:
      "recurring_transactions.show.schedules.history_title",
  },
  columns: {
    reference: "activerecord.external_documents.bill.reference",
    name: "recurring_transactions.show.schedules.name",
    created_at: "recurring_transactions.show.schedules.created_date",
    issueDate: "activerecord.external_documents.invoice.issue_date",
    dateNextRun: "recurring_transactions.show.date_next_run",
    status: "recurring_transactions.show.status",
    actions: "recurring_transactions.table_headers.actions",
  },
};

export default function SchedulesTable({
  isLoading,
  schedulesData,
  type,
  pageSize,
  totalEntries,
  onChange,
  anyFailed,
  currentPage,
  documentType,
  RTStatus,
  isRefetchingSchedules,
  refetchSchedules,
}: Props) {
  const { t } = useTranslation();

  const { token } = theme.useToken();

  const [isSkipModalOpen, setIsSkipModalOpen] = useState(false);

  const {
    mutate,
    mutateAsync,
    isLoading: isExecutingLoading,
  } = useExecuteSchedule();

  const activeRowId = useRef<null | number>(null);

  const startHandlder = (id: number) => {
    mutate({ id, operation: "run" });
  };

  const skipHandlder = async () => {
    try {
      if (activeRowId.current) {
        await mutateAsync({ id: activeRowId.current, operation: "skip" });
      }
    } catch {
      // Todo Display a popup error message once the design is ready
    } finally {
      setIsSkipModalOpen(false);
    }
  };

  const openModalHandler = (id: number) => {
    activeRowId.current = id;
    setIsSkipModalOpen(true);
  };

  const columns = useColumns({
    type,
    anyFailed,
    documentType,
    currentPage,
    onStart: startHandlder,
    onSkip: openModalHandler,
    isLoading: isExecutingLoading && !isSkipModalOpen,
    RTStatus,
    isRefetchingSchedules,
    refetchSchedules,
  });

  const dataSource = useMemo<typeof schedulesData>(() => {
    return schedulesData?.map((record) => ({
      ...record,
      documents:
        record.documents && record.documents.length > 0
          ? record.documents
          : null,
    }));
  }, [schedulesData]);

  return (
    <Flex vertical>
      <Typography.Title level={4} style={{ marginBottom: token.marginLG }}>
        {t(translationTerms.title[type])}
      </Typography.Title>

      <Table<ScheduleData>
        onChange={onChange}
        rowKey={"reference"}
        expandable={{
          childrenColumnName: "documents",
          showExpandColumn: type === ScheduleType.PASSED,
          fixed: "right",
        }}
        pagination={{
          total: totalEntries,
          pageSize: pageSize,
          hideOnSinglePage: true,
          showSizeChanger: false,
        }}
        dataSource={dataSource}
        loading={isLoading}
        columns={columns}
      />
      <SkipProcessModal
        isModalOpen={isSkipModalOpen}
        setIsModalOpen={setIsSkipModalOpen}
        isSkipping={isExecutingLoading}
        onSkipProcess={skipHandlder}
      />
    </Flex>
  );
}

interface ColumnsHookArgs {
  type: ScheduleType;
  anyFailed: boolean | undefined;
  documentType: TransactionDocumentType | undefined;
  currentPage?: number;
  onStart: (id: number) => void;
  onSkip: (id: number) => void;
  isLoading: boolean;
  RTStatus: RTRecord["status"] | undefined;
  isRefetchingSchedules?: boolean;
  refetchSchedules?: () => Promise<void>;
}

const useColumns = ({
  anyFailed,
  documentType,
  type,
  currentPage,
  onSkip,
  onStart,
  isLoading,
  RTStatus,
  isRefetchingSchedules,
  refetchSchedules,
}: ColumnsHookArgs): TableProps<ScheduleData>["columns"] => {
  const { t } = useTranslation();

  const { token } = theme.useToken();

  const { permissions, isPermissionsLoading } = useRTPermissions();

  const documentTypeKey =
    documentType &&
    (documentType === "Bill" || documentType === "Invoice"
      ? documentType.toLocaleLowerCase() + "s"
      : "simple_bills");

  const hasWritePersmission =
    documentTypeKey &&
    !isPermissionsLoading &&
    permissions?.recurring_transactions.write &&
    permissions[documentTypeKey].write;

  return [
    {
      dataIndex: "reference",
      key: "reference",
      title: t(translationTerms.columns.reference),
    },
    {
      dataIndex: "created_at",
      key: "created_at",
      title: t(translationTerms.columns.created_at),
      render(value) {
        return value && dayjs(value).format("YYYY-MM-DD");
      },
    },
    {
      dataIndex:
        type === ScheduleType.UPCOMMING
          ? "scheduled_run_date"
          : "actual_run_date",
      title:
        type === ScheduleType.UPCOMMING
          ? t(translationTerms.columns.dateNextRun)
          : t(translationTerms.columns.issueDate),
      key: "run_date",
      render(value) {
        return value && dayjs(value).format("YYYY-MM-DD");
      },
    },
    {
      dataIndex: "status",
      title: t(translationTerms.columns.status),
      render(value, record) {
        return (
          value && (
            <Tooltip
              title={record.status === "failed" && record.failure_reason}
            >
              <Badge
                count={
                  record.status === "failed" ? (
                    <QuestionCircleOutlined style={{ color: token["red"] }} />
                  ) : null
                }
                offset={[-8, 2]}
              >
                <Status renderAsTag status={value} />
              </Badge>
            </Tooltip>
          )
        );
      },
    },
    {
      title: t(translationTerms.columns.actions),
      render(_, record, index) {
        const isParentRow = record.documents !== undefined;

        const isUpcoming = type === ScheduleType.UPCOMMING;
        const isPassed = type === ScheduleType.PASSED;
        const scheduleStatus = record.status;
        const isScheduleInProgress = scheduleStatus === "in_progress";

        const shouldHideActions =
          isParentRow &&
          (RTStatus === "inactive" ||
            (isUpcoming &&
              (anyFailed || (currentPage && currentPage > 1) || index > 0)) ||
            (isPassed &&
              (scheduleStatus === "success" || scheduleStatus === "skipped")));

        if (shouldHideActions) return null;

        return isParentRow ? (
          hasWritePersmission &&
            (!isScheduleInProgress ? (
              <Space>
                <Button
                  loading={isLoading}
                  icon={<CaretRightOutlined />}
                  onClick={onStart.bind(null, record.id)}
                />
                {!anyFailed && (
                  <Button
                    disabled={isLoading}
                    icon={<CloseOutlined />}
                    onClick={onSkip.bind(null, record.id)}
                  />
                )}
              </Space>
            ) : (
              <Button
                loading={isRefetchingSchedules}
                icon={<ReloadOutlined />}
                onClick={refetchSchedules}
              />
            ))
        ) : (
          <Button
            icon={<EyeOutlined />}
            href={`/tenant/${documentTypeKey}/${record.id}`}
            target="_blank"
          />
        );
      },
    },
  ];
};
