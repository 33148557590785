import React, {useState, useRef, useCallback, useEffect} from 'react'
import { theme, Layout, Spin, ConfigProvider, Tour } from 'antd';
import enUS from 'antd/locale/en_US';
import arEG from 'antd/locale/ar_EG';
import SideMenu from 'components/layout/SideMenu';
import lightTheme from 'themes/light.json';
import Portal from 'components/layout/Portal';
import QLayoutContext from 'contexts/Layout';
import type { ItemData } from 'types/layout';
import {I18nextProvider, initReactI18next} from "react-i18next";
import i18next from "i18next";
import ReactGA from "react-ga4";
import GAMap from 'tokens/GAMap.json';
import Global from 'types/modules/global';

import QFooter from 'components/layout/Footer';
import QHeader from 'components/layout/Header';
import QLogo from 'components/layout/Logo';
import { useIntro } from 'hooks/layout';
import { localeMapValue } from 'types/layout';

const {Sider, Content} = Layout;
const {useToken} = theme;
const {components, ...globalTokens} = lightTheme;

interface LayoutProps {
  sideMenuData: ItemData[];
  isMobileRequest: boolean;
  currentActiveItem: string;
  currentActiveMenu: string;
  comments: {
    viewAllPath: string;
    lastComments: Record<string, any>[];
  },
  tasks: {
    viewAllPath: string,
    lastTasks: Record<string, any>[];
  },
  currentUser: {
    label: string;
    href: string;
  }
  organizationName: string;
  isOnBoard: boolean;
  isTenantActive: boolean;
  suggestionHubUrl: string;
}

i18next
  .use(initReactI18next)
  .init({
    resources: {
      [window.I18n.locale]: {
        translation: window.I18n
      }
    },
    lng: window.I18n.locale
})


const localeMap = new Map<string, localeMapValue>([
  ['en', {locale: enUS, dir: 'ltr', fontFamily: globalTokens.fontFamily}],
  ['ar', {locale: arEG, dir: 'rtl', fontFamily: 'Cairo'}]
])

globalTokens.fontFamily = localeMap.get(i18next.language)?.fontFamily;




const LayoutPage = () => {
    const { token } = useToken();
    const [collapsed, setCollapsed] = useState(false);
    const [collapsedWidth, setCollapsedWidth] = useState(80);
    const [siderWidth, setSiderWidth] = useState<string | number>(264);
    const [mobileStyles, setMobileStyles] = useState({});
    const [logoURL, setLogoURL] = useState('qoyodWLogo');
    const [broken, setBroken] = useState(false);

    const intro = useIntro(window.location.pathname);
    const loaderRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if(broken) {
        setMobileStyles({top: 64, height: `calc(100vh - 64px)`})
      } else {
        setMobileStyles({})
      }
    }, [broken])

    useEffect(() => {
      try {
        ReactGA.initialize(process.env.FRONT_END_GA_ID);
      } catch(e) {
        console.error('Invalid GA');
      }
    }, [])

    useEffect(() => {

      setTimeout(() => {
        if(loaderRef.current) {
          document.querySelector('.wrapper').classList.remove('hide');
          loaderRef.current.remove();
        }
      }, 200)
    }, [])

    const triggerCollapse = useCallback(() => {
      setCollapsed(prevState => !prevState);
      if(!broken && !collapsed) {
        setLogoURL('qLogo');
        document.body.classList.add('sidebar-collapse')

      } else if(!broken && collapsed) {
        setLogoURL('qoyodWLogo')
        document.body.classList.remove('sidebar-collapse')
      }
    }, [collapsed, broken])

    return (
        <Layout>
          <Portal Component={
            <Layout style={{position: 'fixed', zIndex: 1000, width: '100%', height: 0}}>
                <Sider
                  style={{height: '100vh', maxHeight: '100vh', overflow: 'auto', backgroundColor: token.colorPrimary, ...mobileStyles}}
                  breakpoint="lg"
                  collapsedWidth={collapsedWidth}
                  width={siderWidth}
                  onBreakpoint={(broken) => {
                    setBroken(broken);
                    if(broken) {
                      setCollapsedWidth(0);
                      setCollapsed(true);
                      setSiderWidth('100%')
                      setLogoURL('qoyodLogo');
                    } else {
                      setCollapsedWidth(80);
                      setSiderWidth(264);
                      if(collapsed) {
                        setLogoURL('qLogo')
                        document.body.classList.add('sidebar-collapse')
                      } else {
                        setLogoURL('qoyodWLogo');
                        document.body.classList.remove('sidebar-collapse')
                      }
                    }
                  }}
                  trigger={null}
                  collapsible
                  collapsed={collapsed}
                >
                  {broken? null : <QLogo logoURL={logoURL} logoHeight={'50%'} />}
                  <SideMenu startIntro={() => {
                      intro.setOpen(true);
                      ReactGA.event({
                          category: GAMap.showMeHow.CATEGORY,
                          action: GAMap.showMeHow[window.location.pathname]
                      });}}
                      collapsed={collapsed}
                      broken={broken}
                  />
              </Sider>
              <Layout>
                <QHeader broken={broken} logoURL={logoURL} startIntro={() => intro.setOpen(true)} collapsed={collapsed} triggerCollapse={triggerCollapse} />
              </Layout>
            </Layout>

          } htmlRoot="portal__sider_header"/>

          <Portal Component={
            <QFooter broken={broken} />
          } htmlRoot="portal__footer"/>

          <Portal Component={
            <Layout ref={loaderRef}>
              <Content style={{background: token.colorBgContainer, height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Spin size="large" />
              </Content>
            </Layout>
          } htmlRoot="portal__loader" />
          <Tour open={intro.open} onClose={() => intro.setOpen(false)} steps={intro.steps} scrollIntoViewOptions={{block: 'center'}} />
        </Layout>
    )
}

export default ({sideMenuData, currentActiveItem, currentActiveMenu, isMobileRequest, currentUser, organizationName, comments, tasks, isOnBoard, isTenantActive, suggestionHubUrl}: LayoutProps) => (
  <ConfigProvider theme={{components, token: globalTokens}} locale={localeMap.get(i18next.language)?.locale} direction={localeMap.get(i18next.language)?.dir}>
    <QLayoutContext.Provider value={{sideMenuData, currentActiveItem, currentActiveMenu, isMobileRequest, currentUser, organizationName, comments, tasks, isOnBoard, isTenantActive, suggestionHubUrl}}>
      <I18nextProvider i18n={i18next}>
        <LayoutPage />
      </I18nextProvider>
    </QLayoutContext.Provider>
  </ConfigProvider>
);
