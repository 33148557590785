import React, {useEffect, useState, useContext, useCallback} from 'react';
import {Layout, Space, Typography, Dropdown, Button, Avatar, Divider, MenuProps, Drawer, Menu} from 'antd';
import {MessageOutlined, QuestionCircleOutlined, UserOutlined, MenuOutlined, MenuFoldOutlined, MenuUnfoldOutlined} from '@ant-design/icons';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import {theme} from 'antd';
import QLogo from './Logo';
import QIcon from 'components/layout/QIcon';
import QLayoutContext from 'contexts/Layout';
import { useComments, useHelp, useTasks, useUserSetting } from 'hooks/layout';
import ReactGA from 'react-ga4';
import GAMap from 'tokens/GAMap.json';
import TasksOutlined from 'assets/icons/Tasks-Outlined.svg'

const {useToken} = theme;

const {Header} = Layout;
const {Text, Link, Paragraph} = Typography;

const trackGAEvent = (action) => {
  ReactGA.event({
    category: GAMap.header.CATEGORY,
    action: action
  });
}

const mobileHeader = new Map<string, unknown>([
    ['Position', 'absolute'],
    ['Content', ({triggerCollapse, comments, tasks, helpItems, userSettings, getLatestTasks}) => {

      const [open, setOpen] = useState(false);
      const [items, setItems] = useState([]);

      const showDrawer = () => {
        setOpen(true);
      };

      const onClose = () => {
        setOpen(false);
      };

      useEffect(() => {
        setItems(tasks);
      }, [ tasks ])

      return (
        <>
          <Space>

            <Button onClick={() => {setItems(comments); showDrawer(); trackGAEvent(GAMap.header.mentions.expand)}} type="text" icon={<MessageOutlined />} />
            <Button onClick={() => {setItems(helpItems); showDrawer()}} type="text" icon={<QuestionCircleOutlined />} />
            <Button onClick={() => {getLatestTasks(); showDrawer(); trackGAEvent(GAMap.header.tasks.expand)}} type="text" icon={<QIcon component={TasksOutlined} />} />
            <Button onClick={() => {setItems(userSettings); showDrawer()}} type="text" icon={<UserOutlined />} />
            <Button
              type="text"
              icon={<MenuOutlined />}
              onClick={triggerCollapse}
            />
          </Space>
          <Drawer placement="top" onClose={onClose} open={open} closeIcon={null}>
            <Space direction="vertical" style={{width: '100%'}}>
              {items.map(item => item.label)}
            </Space>
          </Drawer>
        </>
    )}]
  ]);

  const pcHeader = new Map<string, unknown>([
    ['Position', 'relative'],
    ['Content', ({triggerCollapse, collapsed, currentUser, organizationName, comments, tasks, helpItems, userSettings, getLatestTasks}) => (
      <div style={{display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between', gap: 8}}>
        <div style={{display: 'flex', alignItems: 'center', gap: 8, width: '50%'}}>
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined style={{transform: i18next.dir() === 'rtl'? 'rotate(180deg)' : ''}} /> : <MenuFoldOutlined style={{transform: i18next.dir() === 'rtl'? 'rotate(180deg)' : ''}} />}
            onClick={triggerCollapse}
            style={{
              padding: 0
            }}
          />
          <Paragraph style={{margin: 0, width: 'calc(100% - 40px)'}} ellipsis={{rows: 1}}>{useTranslation().t('activerecord.attributes.layout.welcome')} <Link href={currentUser.href} onClick={() => trackGAEvent(GAMap.header.editProfile)} strong>{currentUser.label}</Link> {useTranslation().t('activerecord.attributes.layout.in_orgnization')} <Text strong>{organizationName}</Text></Paragraph>
        </div>
        <Space size={16} split={<Divider type="vertical" />}>
          <Space>
            <Dropdown onOpenChange={(opened) => trackGAEvent(opened? GAMap.header.mentions.expand : GAMap.header.mentions.collapse)} menu={{ items: comments, style: {width: 443, maxHeight: '90vh', overflow: 'auto', border: '1px solid rgba(0, 0, 0, 10%)', padding: 0} }} trigger={["click"]}>
              <Button icon={<MessageOutlined />} />
            </Dropdown>
            <Dropdown menu={{ items: helpItems, style: {border: '1px solid rgba(0, 0, 0, 10%)'} }} trigger={["click"]}>
              <Button icon={<QuestionCircleOutlined />} />
            </Dropdown>
            <Dropdown onOpenChange={(opened) => { trackGAEvent(opened? GAMap.header.tasks.expand : GAMap.header.tasks.collapse); opened && getLatestTasks()}} menu={{ items: tasks, style: {width: 443, maxHeight: '90vh', overflow: 'auto', border: '1px solid rgba(0, 0, 0, 10%)', padding: 0} }} trigger={["click"]}>
              <Button icon={<QIcon style={{fill: 'black'}} component={TasksOutlined} />} />
            </Dropdown>
          </Space>
          <Space size={8} align="center">
            <Avatar shape="square" size="large" src={currentUser.tenantLogo} />
            <Dropdown.Button menu={{items: userSettings, style: {border: '1px solid rgba(0, 0, 0, 10%)'}}} onClick={() => {}} trigger={["click"]}>
                {organizationName}
            </Dropdown.Button>
          </Space>
        </Space>
      </div>
    )]
  ])


const QHeader = ({broken, collapsed, logoURL, triggerCollapse, startIntro}) => {
    const {token} = useToken();
    const [headerView, setHeaderView] = useState(null);
    const {currentUser, organizationName} = useContext(QLayoutContext);
    const comments = useComments();
    const { tasks, getLatestTasks } = useTasks();
    const helpItems = useHelp(broken, startIntro);
    const userSettings = useUserSetting();

    const menuTrigger = useCallback(() => {
      triggerCollapse();
      const eventID = collapsed? GAMap.header.menuTrigger.expand : GAMap.header.menuTrigger.collapse;
      trackGAEvent(eventID);
    }, [triggerCollapse])

    useEffect(() => {
        if(broken) {
            setHeaderView(mobileHeader);
        } else {
            setHeaderView(pcHeader)
        }
    }, [broken])

    const HeaderContent = headerView?.get('Content') ?? (() => null);

    return (
        <Header style={{ padding: 0, background: token.colorBgContainer, width: '100%', left: 0, position: headerView?.get('Position') }}>
            <div style={{paddingInline: token.paddingMD, display: 'flex', justifyContent: 'space-between'}}>
                {broken? <QLogo logoURL={logoURL} logoHeight={'80%'} /> : null}
                <HeaderContent triggerCollapse={menuTrigger} collapsed={collapsed} currentUser={currentUser} organizationName={organizationName} comments={comments} tasks={tasks} helpItems={helpItems} userSettings={userSettings} getLatestTasks={getLatestTasks} />
            </div>
        </Header>
    )
}

export default QHeader
